/* ======================
    Hamburger Menu
=========================*/

.menu-hamburger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  pointer-events: auto;
  height: 19px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  color: #fff;

  &.gray-version {
    color: #999;
  }

  div {
    position: relative;
    width: 24px;
    height: 18px;
    pointer-events: auto;

    i {
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      width: 100%;
      height: 2px;
      background: currentColor;

      &::before,
      &::after {
        position: absolute;
        left: 50%;
        width: 100%;
        height: 100%;
        background: inherit;
        content: "";
        -webkit-transition: width .3s cubic-bezier(.645, .045, .355, 1);
        transition: width .3s cubic-bezier(.645, .045, .355, 1);
        -webkit-transform: translate(-50%);
        -ms-transform: translate(-50%);
        transform: translate(-50%);
      }

      &::before {
        top: -8px;
      }

      &::after {
        top: 8px;
      }
    }

  }

  &:hover {
    div {
      i {
        &::before,
        &::after {
          width: 70%;
        }
      }
    }
  }
}


.black-logo--version, .dark-logo--version {
  .menu-hamburger{
    color: #222222;
  }
}

.gray-version {
  .menu-hamburger{
    color: #999;
  }
}

/* Page Close Button */
.open-hamburger-wrapper {
  .page-close {
    position: fixed;
    top: 70px;
    right: 100px;
    z-index: 100000;
    font-size: 100px;
    line-height: 0;
    cursor: pointer;
    height: 40px;
    width: 40px;
    -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
    transition: all .3s cubic-bezier(.645, .045, .355, 1);

    &::before,
    &::after {
      position: absolute;
      top: 19px;
      left: 4px;
      content: "";
      width: 32px;
      height: 3px;
      background: #222;
      -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
    }

    &::before {
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &::after {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:hover {
      &::before,
      &::after {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }


    &.light-version {
      &::before,
      &::after {
        background: #fff,
      }
    }
  }
}


.open-hamburger-wrapper {
  background-image: url('../../img/bg/bg-menu.jpg');
  background-color: #222;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // -webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
  // transition: all .3s cubic-bezier(.645, .045, .355, 1);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  visibility: hidden;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  -webkit-transform: scale(.3);
  -ms-transform: scale(.3);
  transform: scale(.3);
  padding: 100px 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
  padding-top: 60px;

  .menu-container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8rem;
    font-size: 48px;

    a{
      color: #fff;
      text-decoration: none;
      // font-weight: bold;
      font-size: 48px;
      transition: all .3s cubic-bezier(.645, .045, .355, 1);
      &:hover{
        color: $creative-color;
        text-decoration: none;
        font-size: 48px;
      }
    }
  }

  &.is-visible {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
}


/* Hamberger Wrapper */

.open-hamburger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu {
  justify-content: flex-start;
}

.open-hamburger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
  line-height: 1.4;
  font-size: 48px;
}


/* Hamberger Search */
.hamburger-search {
  margin-top: 230px;
  max-width: 420px;
  margin-bottom: 148px;

  .input-box {
    position: relative;

    input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #eee;
      padding: 28px 50px 28px 0;
      width: 420px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      border: 0;
      font-size: 0;
      padding: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      box-shadow: none;
      height: 62px;

      i {
        font-size: 15px;
      }
    }
  }
}


@media #{$laptop-device}{
  .open-hamburger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
    font-size: 30px;
  }
}

@media #{$lg-layout}{
  .open-hamburger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
    font-size: 20px;
  }
}

@media #{$md-layout}{
  .open-hamburger-wrapper .mainmenu-wrapper .page_nav ul.mainmenu li.label-1 > a {
    font-size: 20px;
  }
}










